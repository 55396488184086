
.modal{
    width: 400px;
    /* position: relative; */
}

.modal:focus-visible{
    outline: none;
}

.modalPosition:focus-visible{
    outline: none;
}

.closeBtn{
    /* width: 30px; */
    border-radius: 5px;
    padding: 1px 12px;
    height: 30px;
    color: white;
    background-color: black;
    position: absolute;
    right: 8px;
    top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    margin: 0;
}

.modalPosition{
    /* position: absolute; */
    margin: 10% auto 0;
    /* left: 50%; */
    width: fit-content;
    /* transform: translate(-50%, -50%); */
}

@media screen and (max-width : 1024px) {
    .modalPosition{
        /* position: absolute; */
        margin: 30% auto;
        /* left: 50%; */
        width: fit-content;
        /* transform: translate(-50%, -50%); */
    }
}

@media screen and (max-height : 820px) {
    .modalPosition{
        position: absolute;
        top: 2.5%;
        left: 2.5%;
        /* margin: 20% auto; */

}
}

@media screen and (max-height : 668px) {
    .modalPosition{
        position: absolute;
        top: -5%;
        left: 2.5%;
        /* margin: 20% auto; */

}
}

@media screen and (max-width : 660px) {
    .modalPosition{
        /* position: absolute; */
        /* top: 13%; */
        left: 7%;
        margin: 13% 0;

}
    .modal{
        width: 292px;
        /* height: 100%; */
        position: relative;
    }
    .closeBtn{
        /* width: 30px; */
        border-radius: 5px;
        padding: 1px 12px;
        height: 30px;
        color: white;
        background-color: black;
        position: absolute;
        right: 8px;
        top: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-weight: 600;
        margin: 0;
    }
}


@media screen and (max-width : 385px) {
    .modalPosition{
        /* position: absolute; */
        /* top: 13%; */
        left: 2.5%;
        margin: 13% 0;

}

    .modal{
        width: 292px;
        /* height: 100%; */
        position: relative;
    }
    .closeBtn{
        /* width: 30px; */
        border-radius: 5px;
        padding: 1px 12px;
        height: 30px;
        color: white;
        background-color: black;
        position: absolute;
        right: 8px;
        top: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-weight: 600;
        margin: 0;
    }
}
